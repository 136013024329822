.chart {
  padding: 20px;
  background: var(--grey-400);
  position: relative;
}

.chart__wrapper {
  position: relative;
  border-radius: 3px;
  background: var(--grey-400);
}

.chart__header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 50px;
}

.chart__info {
  display: flex;
  flex-flow: column nowrap;
}

.chart__title {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  margin-bottom: 5px;
  color: var(--grey-100);
}

.chart__subtitle {
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  color: var(--grey-200);
}

.chart__actions {
  display: flex;
  flex-flow: row nowrap;
}

.chart__button {
  width: 40px;
  height: 29px;
  padding: 0;
  border: none;
  background: none;
  margin-right: 5px;
  border: 2px solid black;
  border: none;
  border: 2px solid var(--grey-700);
  border-radius: 3px;
  cursor: pointer;
}

.chart__button_export {
  margin-left: 40px;
}

.chart__graph {
  color: var(--grey-200);
  color: black;
}

.chart__spinner {
  height: 320px;
  display: flex;
  align-items: center;
}

.chart-no-data {
  height: 320px;
  line-height: 320px;
  text-align: center;
  font-size: 18px;
  color: inherit;
  color: var(--grey-100);
}

.chart__watermark {
  display: none;
}

.chart,
.chart + .datetime__form {
  transition: background 0.25s ease-out;
}

.chart_print * {
  cursor: progress;
}

.chart_print,
.chart_print + .datetime__form {
  background: var(--white-500);
  transition: none;
  cursor: progress;
}

.chart_print + .datetime__form .datetime__form-group {
  opacity: 0;
}

.chart_print .chart__title {
  color: var(--black-500);
}

.chart_print .chart__actions {
  display: none;
}

.chart_print .chart__watermark {
  display: block;
  position: absolute;
  bottom: 20px;
  right: 30px;
  opacity: 0.5;
  width: 100px;
}

/* Rechart adaptations */

.recharts-legend-item {
  margin-bottom: 5px;
}

.recharts-surface {
  transform: translate(-2px, -1px);
}

.recharts-legend-item:last-child {
  margin-bottom: 0;
}

/* SVG BUTTONS STYLING */

.svg-chart__grid {
  fill: var(--grey-150);
}

.svg-chart__bg {
  fill: var(--grey-400);
}

.svg-chart__line {
  fill: var(--grey-150);
}
.svg-chart__pie-cls-1 {
  fill: #595f65;
}
.svg-chart__pie-cls-2 {
  fill: var(--grey-200);
}
.svg-chart__pie-cls-3 {
  fill: var(--grey-150);
}

.chart-button_active {
  border-color: var(--blue-500);
}

.chart-button_active .svg-chart__line {
  fill: var(--blue-500);
}

.chart-button_active .svg-chart__pie-cls-1 {
  fill: var(--orange-500);
}

.chart-button_active .svg-chart__pie-cls-2 {
  fill: var(--green-500);
}

.chart-button_active .svg-chart__pie-cls-3 {
  fill: var(--blue-500);
}
