.graphs-form_triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 0 7.5px 10px;
  border-color: transparent transparent transparent var(--blue-400);
}

.graphs__container .container-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
}
