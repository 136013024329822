:root {
  --container-header-height: 30px;
}

.container {
  margin: 20px;
  padding: 20px 30px;
  border-radius: 2px;
  position: relative;
  scrollbar-color: var(--color-fg-scroll) var(--grey-400);
  min-width: 400px;
}

.container + .container {
  margin-top: 0;
}

.container-fixed_wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: var(--color-bg-base);
}

.container-fixed_wrapper .container {
  margin-bottom: 0;
}

.container-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 30px;
  overflow: auto;
}

.container-content_no-title {
  margin-top: 0;
}

.container-title {
  font-family: 'neo-sans', sans-serif;
  font-size: 15px;
  color: var(--color-fg-highlight);
  height: var(--container-header-height);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.container__title-column {
  flex-direction: column;
  align-items: flex-start;
}

.container-title_block {
  display: flex;
  min-width: 150px;
  column-gap: 20px;
}

.container__title-column .container-title_block:first-child {
  margin-bottom: 10px;
}

.container-title_block:first-child {
  margin-right: 30px;
}

.container-title_block_push-right {
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0;
}

.container-title_block_help {
  border-radius: 50%;
  background-color: #444751;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' viewBox='0 0 6.19 13.04'%3E%3Cpath fill='%23e2e2e2' d='M4.84 12.23V5.31h-.01v-.49H.06v.98H1.4v6.43H0v.81h6.19v-.81H4.84zM5 1.91A1.91 1.91 0 113.12 0 1.92 1.92 0 015 1.91'/%3E%3C/svg%3E");
  background-size: 4px;
  background-position: 5px 3px;
  cursor: pointer;
  position: relative;
  width: 14px;
  height: 14px;
  left: 5px;
  top: 1px;
}

.container-search {
  position: relative;
  width: 250px;
}

.input.container-search__input {
  padding-right: 25px;
}

.container-search__icon {
  height: 15px;
  position: absolute;
  top: 9px;
  right: 5px;
}

.container__button {
  background: var(--color-bg-table-stripes);
  color: var(--color-fg-form-field-label);
  font-family: var(--default-font-family);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 14px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  padding: 0 15px;
  text-decoration: none;
}

.container__button svg {
  height: 12px;
  width: auto;
  margin-right: 5px;
}

.container_fixed-footer .container-content {
  margin-bottom: 60px;
}

.container_fixed-footer .table__footer {
  position: absolute;
  bottom: 20px;
  left: 30px;
  width: calc(100% - 60px);
}

.container_row .container-content {
  flex-direction: row;
  justify-content: space-between;
}

.container_scrollable {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.container-bottom_help_container {
  color: var(--color-fg-base);
  background-color: var(--color-bg-table-stripes);
  text-align: center;
  margin-top: 3px;
}

.container__help-text_action {
  color: var(--color-fg-form-field-label);
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
}

.container__help-text_bottom {
  opacity: 0.5;
  text-align: center;
  line-height: 36px;
  font-family: 'MyriadPro', sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: auto;
}

@media screen and (max-width: 1280px), screen and (max-height: 968px) {
  .container {
    padding: 13px 20px;
  }

  .container-content {
    margin-top: 15px;
  }

  .container-content_no-title {
    margin-top: 0;
  }
}
