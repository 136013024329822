.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(510px, 1fr));
  gap: 50px;
}

.graph-no-data {
  height: calc(100% - 200px);
}

.stats-period {
  background: var(--grey-400);
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 40px;
}

.chart__wrapper {
  background: none;
}

.chart__wrapper .chart {
  border-radius: 6px;
}

@media (min-width: 1921px) {
  .stats-grid {
    grid-template-columns: repeat(auto-fit, minmax(710px, 1fr));
  }
}
