.datetime__form-group {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 12px;
  padding: 20px;
  padding-top: 0;
}

.datetime__form-group .input__icon {
  display: none;
}

.datetime__form-group .formfield {
  max-width: 115px;
  margin-bottom: 0;
  flex: 0;
}

.datetime__form-group .formfield:last-child {
  margin-right: 0;
}

.next-step-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 18px;
  border-color: transparent transparent transparent var(--grey-150);
  flex-shrink: 0;
  margin-bottom: 5px;
}

.datetime__form .next-step-triangle {
  transform: scale(0.7);
  margin-bottom: 0;
}

.datetime-form_checkbox-group {
  display: flex;
  flex-direction: row;
  height: 20px;
  column-gap: 8px;
}

.datetime-form_checkbox-group .formfield__label {
  line-height: 20px;
}
